import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
type PageInfo = {
  pageId: number;
  pageName: string;
};

const StyledTextarea = styled.textarea`
  padding: 14px;
  font-size: 16px;
  width: 133.333%;
  transform: scale(0.75);
  transform-origin: left top;
  margin-bottom: -10px;

  @media (min-width: 640px) {
    font-size: 15px;
    margin-bottom: 0;
    transform: scale(1);
    width: 100%;
  }

  @media (min-width: 1920px) {
    font-size: 17px;
  }

  /* Additional styles specific to textarea */
  border: 1px solid #e5e7eb; /* Gray border */
  border-radius: 8px; /* Rounded corners */
  resize: none; /* Disable resizing */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
`;

const FeedBackButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [survey, setSurvey] = useState<any[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [userResponses, setUserResponses] = useState<any[]>([]);
  const modalRef = useRef<HTMLDivElement>(null); // Modal ref to change styles dynamically

  const router = useRouter(); // Get the current URL
  const resetResponses = survey.map((surveyItem: any) => ({
    questionId: surveyItem.questionId,
    text: surveyItem.text,
    type: surveyItem.type,
    options: surveyItem.options || [],
    userResponse: surveyItem.type === 'multiple-choice' ? [] : ''
  }));

  const pageMap: { [key: string]: PageInfo } = {
    '/': { pageId: 1, pageName: 'Landing Page' },
    '/checkout': { pageId: 3, pageName: 'Checkout Page' }
  };

  const getPageInfo = () => {
    if (router.pathname.startsWith('/tour')) {
      return { pageId: 2, pageName: 'Tour Page' };
    }

    // Return page info from the map or a fallback if the route is not in the map
    return pageMap[router.pathname] || { pageId: null, pageName: '' };
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { pageId, pageName } = getPageInfo();

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await axios.get(
          `https://us-central1-staging-c0818.cloudfunctions.net/api/get_survey_by_page?pageId=${pageId}`
        );
        const surveys = response.data.data.map((surveyItem: any) => ({
          questionId: surveyItem.questionId,
          text: surveyItem.text,
          type: surveyItem.type,
          options: surveyItem.options || [],
          userResponse: surveyItem.type === 'multiple-choice' ? [] : ''
        }));

        setSurvey(surveys);
        setUserResponses(surveys);
        setLoading(false);
      } catch (err) {
        console.log('Error fetching survey:', err);
        setError('Failed to load survey. Please try again later.');
        setLoading(false);
      }
    };

    fetchSurvey();
  }, [pageId]);

  const submitSurvey = async (e: any) => {
    e.preventDefault();

    // If the button is already disabled (submitting), do nothing
    if (isSubmitting) return;

    setIsSubmitting(true); // Disable the button
    const hasAnswered = userResponses.some(response => {
      if (response.type === 'multiple-choice') {
        return response.userResponse.length > 0;
      }
      return response.userResponse !== '';
    });

    if (!hasAnswered) {
      setError('Please answer at least one question.');
      setIsSubmitting(false); // Re-enable the button if there's an error
      return;
    }

    const requestBody = {
      pageId: pageId,
      pageName: pageName,
      survey: userResponses.map(response => ({
        questionId: response.questionId,
        text: response.text,
        userResponse: response.userResponse
      }))
    };

    try {
      const response = await axios.post(
        'https://us-central1-staging-c0818.cloudfunctions.net/api/submit_survey',
        requestBody
      );
      toast.success('Survey submitted successfully');
      setUserResponses(resetResponses);
      setError('');
      closeModal();
    } catch (error) {
      console.error('Error submitting survey:', error);
      setError('Failed to submit the survey. Please try again later.');
    } finally {
      setIsSubmitting(false); // Re-enable the button after submission is done
    }
  };

  const openModal = () => {
    setIsOpen(true);
    setCurrentStep(0);
  };

  const closeModal = () => {
    setIsOpen(false);
    setUserResponses(resetResponses);
  };

  const nextStep = () => {
    setCurrentStep(prevStep => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  const handleInputChange = (questionId: string, value: any) => {
    setUserResponses(prevResponses => {
      const updatedResponses = prevResponses.map(response =>
        response.questionId === questionId
          ? { ...response, userResponse: value }
          : response
      );

      const hasAnswered = updatedResponses.some(response => {
        if (response.type === 'multiple-choice') {
          return response.userResponse.length > 0;
        }
        return response.userResponse !== '';
      });

      if (hasAnswered) {
        setError('');
      }

      return updatedResponses;
    });
  };

  const handleOptionChange = (questionId: string, option: string) => {
    setUserResponses(prevResponses => {
      const updatedResponses = prevResponses.map(response =>
        response.questionId === questionId
          ? {
              ...response,
              userResponse: response.userResponse.includes(option)
                ? response.userResponse.filter((opt: string) => opt !== option)
                : [...response.userResponse, option]
            }
          : response
      );

      const hasAnswered = updatedResponses.some(response => {
        if (response.type === 'multiple-choice') {
          return response.userResponse.length > 0;
        }
        return response.userResponse !== '';
      });

      if (hasAnswered) {
        setError('');
      }

      return updatedResponses;
    });
  };

  const totalSteps = survey.length;

  const currentSurvey = survey[currentStep];
  useEffect(() => {
    const preventBodyScroll = (e: TouchEvent) => {
      if (e.target instanceof HTMLElement && e.target.tagName !== 'TEXTAREA') {
        // Prevent touch scroll for anything other than textarea
        e.preventDefault();
      }
    };

    const enableTextareaScroll = (e: TouchEvent) => {
      const textarea = e.target as HTMLTextAreaElement;

      if (textarea.scrollHeight > textarea.clientHeight) {
        const isAtTop = textarea.scrollTop === 0;
        const isAtBottom =
          textarea.scrollTop + textarea.clientHeight === textarea.scrollHeight;

        if (
          (isAtTop && e.touches[0].clientY > 0) || // Scroll up at top
          (isAtBottom && e.touches[0].clientY < 0) // Scroll down at bottom
        ) {
          e.preventDefault(); // Prevent scroll if it's at the edge
        }
      }
    };

    if (isOpen) {
      // Disable body scrolling
      document.body.style.overflow = 'hidden';

      // Add event listener to prevent body scroll but allow textarea scrolling
      document.addEventListener('touchmove', preventBodyScroll, {
        passive: false
      });

      const textareas = document.querySelectorAll('textarea');
      textareas.forEach(textarea =>
        textarea.addEventListener('touchmove', enableTextareaScroll, {
          passive: false
        })
      );
    } else {
      // Re-enable body scroll when modal is closed
      document.body.style.overflow = 'unset';

      // Remove event listeners
      document.removeEventListener('touchmove', preventBodyScroll);

      const textareas = document.querySelectorAll('textarea');
      textareas.forEach(textarea =>
        textarea.removeEventListener('touchmove', enableTextareaScroll)
      );
    }

    return () => {
      // Cleanup when component is unmounted
      document.body.style.overflow = 'unset';
      document.removeEventListener('touchmove', preventBodyScroll);

      const textareas = document.querySelectorAll('textarea');
      textareas.forEach(textarea =>
        textarea.removeEventListener('touchmove', enableTextareaScroll)
      );
    };
  }, [isOpen]);

  const renderQuestion = (questionData: any) => {
    const { questionId, text, options, type, userResponse } = questionData;

    switch (type) {
      case 'text':
        return (
          <div>
            <p className="text-[#343434] text-[14px]  md:text-[16px] lg:text-[18px] font-semibold mb-4">
              {text}
            </p>
            <StyledTextarea
              className="w-full border border-gray-300 focus:outline-none focus:border-gray-500 rounded-md p-2 resize-none overflow-auto h-[150px] touch-manipulation"
              rows={4}
              placeholder="Your answer..."
              value={userResponse}
              onChange={e => {
                handleInputChange(questionId, e.target.value);
              }}
            />
          </div>
        );
      case 'multiple-choice':
        return (
          <div>
            <p className="text-[#343434] text-[14px]  md:text-[16px] lg:text-[18px] font-semibold mb-4">
              {text}
            </p>
            {options.map((option: any, index: any) => (
              <label
                key={index}
                className="flex items-center mb-2 text-[14px] md:text-[15px] lg:text-[17px] text-gray75"
              >
                <input
                  type="checkbox"
                  className="mr-2 lg:text-[17px] text-gray75"
                  checked={userResponse.includes(option)}
                  onChange={() => handleOptionChange(questionId, option)}
                />
                {option}
              </label>
            ))}
          </div>
        );
      case 'radio':
        return (
          <div>
            <p className="text-[#343434] text-[14px] md:text-[16px] lg:text-[18px] font-semibold mb-4">
              {text}
            </p>
            {options.map((option: any, index: any) => (
              <label
                key={index}
                className="flex items-center mb-2 text-[14px] md:text-[15px] lg:text-[17px] text-gray75"
              >
                <input
                  type="radio"
                  name={questionId}
                  className="mr-2 lg:text-[17px] text-gray75"
                  value={option}
                  checked={userResponse === option}
                  onChange={() => handleInputChange(questionId, option)}
                />
                {option}
              </label>
            ))}
          </div>
        );

      case 'rating':
        return (
          <div>
            <p className="text-[#343434] text-[14px]  md:text-[16px] lg:text-[18px] font-semibold mb-4">
              {text}
            </p>
            <div className="flex flex-col">
              {options.map((option: any, index: any) => (
                <label key={index} className="flex items-center mb-2">
                  <input
                    type="radio"
                    name={questionId}
                    className="hidden"
                    value={option}
                    checked={userResponse === option}
                    onChange={() => handleInputChange(questionId, option)}
                  />
                  <div className="flex items-center cursor-pointer">
                    {[...Array(Number(option))].map((_, starIndex) => (
                      <Image
                        key={starIndex}
                        src={'/rating-star-yellow.svg'}
                        width={20}
                        height={20}
                        alt="rating star"
                        className="mr-1"
                      />
                    ))}
                    <span className="ml-2">{option}</span>
                  </div>
                </label>
              ))}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <button
        onClick={openModal}
        className={`fixed ${
          isOpen ? 'opacity-60' : 'opacity-100'
        } right-0 top-1/2 transform -translate-y-1/2 bg-primary text-white py-2 px-4 rounded-l-[20px] z-[1200] flex items-center justify-center`}
        style={{
          width: '36px',
          height: '116px',
          zIndex: 1201
        }}
      >
        <span className="rotate-90">Feedback</span>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center pr-10 msm:pr-12 md:pr-14 justify-end z-[1200]">
          <div
            className="bg-white rounded-lg overflow-hidden relative top-[80px] sm:top-[100px] lg:top-[110px] xl:top-[120px] 3xl:top-[200px] w-[270px] xmsm:w-[300px] xsm:w-[320px] md:w-[380px]"
            ref={modalRef}
          >
            <div className="bg-primary text-white flex justify-between items-center h-[40px] p-[10px] md:p-[20px]">
              <Image alt="logo" src="/logo-white.svg" width={25} height={25} />
              <h2 className="text-[14px] md:text-[16px] font-semibold">
                Tripshepherd Survey
              </h2>
              <button onClick={closeModal}>
                <Image
                  alt="cross-icon"
                  src="/cross-border-icon.svg"
                  width={25}
                  height={25}
                />
              </button>
            </div>
            <div className="px-4 py-2 msm:px-6 msm:py-4">
              <div className="min-h-[180px]">
                {currentSurvey && renderQuestion(userResponses[currentStep])}
              </div>
              {error && currentStep === totalSteps - 1 && (
                <p className="text-red-500 text-sm mt-2">{error}</p>
              )}
              <div
                className={`flex ${
                  currentStep === 0 ? 'justify-end' : 'justify-between'
                } mt-6`}
              >
                {currentStep > 0 && (
                  <button
                    onClick={prevStep}
                    className="bg-white text-primary text-[14px] lg:text-base px-8 py-2 w-[107px] border border-primary rounded-[30px]"
                  >
                    Back
                  </button>
                )}
                {currentStep < totalSteps - 1 ? (
                  <button
                    onClick={nextStep}
                    className="bg-primary text-[14px] lg:text-base text-white px-8 py-2 w-[107px] rounded-[30px]"
                  >
                    Next
                  </button>
                ) : (
                  <div>
                    <button
                      onClick={submitSurvey}
                      className={`bg-primary text-[14px] lg:text-base text-white px-8 py-2 w-[107px] rounded-[30px] ${
                        isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
              <p className="text-gray-500 text-[12px] lg:text-sm text-right mt-2">
                Question {currentStep + 1} of {totalSteps}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedBackButton;
