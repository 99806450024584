/* eslint-disable @next/next/no-page-custom-font */
/* eslint-disable @next/next/no-img-element */
import '../styles/globals.scss';

import type { AppProps } from 'next/app';

const Footer = dynamic(() => import('../layouts/Footer/footer'));

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Script from 'next/script';
import dynamic from 'next/dynamic';
import navigationalSchema from '../utils/navigationSchema';
import organizationSchema, {
  WebsiteSchema
} from '../utils/organizationalSchema';
import { PaxProvider } from '../utils/context/checkoutContext';
import { AuthContextProvider } from '../utils/context/authContext';
import NavBar from '../layouts/Navbar/NavBar';
import { Poppins } from 'next/font/google';
import { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { AttractionCheckoutProvider } from '../utils/attractionCheckoutContext';
import { useMediaQuery } from '../hooks/mediaQuery';
import { SearchProvider } from '../utils/context/searchContext';
import FeedBackButton from '../shared-components/FeedbackButton';
const Notifications = dynamic(
  () => import('../components/BookingNotifications')
);
const AppoloProvider = dynamic(() => import('../utils/dynamicAppolo'), {
  ssr: true
});
declare global {
  interface Window {
    gtag?: any;
  }
}
const poppins = Poppins({
  subsets: ['latin'],
  weight: ['400', '600', '700', '300']
});
export default function App({ Component, pageProps }: AppProps) {
  const [delayAnalyticsLoad, setAnalyticsLoad] = useState<boolean>(false);
  const router = useRouter();
  const { slug2 } = router.query;
  const isMobileScreen = useMediaQuery(639);

  // Check if the second slug is "things-to-do"
  const isThingsToDoPage = slug2?.includes('things-to-do');

  const routesWithoutNavBar = [
    '/terms-and-conditions',
    '/privacy-policy',
    '/goat-terms-and-conditions',
    '/goat-privacy-policy',
    '/checkout',
    '/signin',
    '/signup',
    '/forget-password'
  ];

  const routesWithoutFooter = [
    '/signin',
    '/signup',
    '/forget-password',
    '/reset-password',
    '/goat-terms-and-conditions',
    '/goat-privacy-policy',
    '/terms-and-conditions',
    '/privacy-policy',
    '/checkout',
    '/tours/[slug]',
    '/things-to-do'
  ];

  // Check if the current route is excluded
  const isExcludedRoute = routesWithoutFooter.includes(router.route || '');

  // Conditional rendering logic
  const shouldShowFooter = !(
    (isThingsToDoPage &&
      router.route === '/[slug]/[slug2]' &&
      isMobileScreen) ||
    isExcludedRoute
  );
  const allowedRoutes = ['/', '/checkout'];
  const isTourPage = router.pathname.startsWith('/tours/');
  const shouldShowFeedbackButton =
    allowedRoutes.includes(router.pathname) || isTourPage;
  useEffect(() => {
    const analyticsTimeout = setTimeout(() => {
      setAnalyticsLoad(true);
    }, 10000);
    return () => clearTimeout(analyticsTimeout);
  }, []);
  // const [isHydrated, setIsHydrated] = useState(false);
  // useEffect(() => {
  //   if (typeof window.gtag !== 'undefined') {
  //     setAnalyticsLoad(true);
  //   }
  // }, []);
  // useEffect(() => {
  //   setIsHydrated(true);
  // }, []);
  // if (!isHydrated) {
  //   return null; // Render nothing on the initial render to ensure consistency
  // }
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="og:type" content="website" />
        <meta property="og:site_name" content="Tripshepherd" />

        <meta
          name="google-site-verification"
          content="5SV-gMUYtvmmRozSFJH07XSg4yE9xGoJlKrdQHlTC8U"
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(navigationalSchema)
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(w, r) {
            w._rwq = r;
            w[r] = w[r] || function() { (w[r].q = w[r].q || []).push(arguments) };
          })(window, 'rewardful');
        `
          }}
        />
        <script
          async
          src="https://r.wdfl.co/rw.js"
          data-rewardful="13f729"
        ></script>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
          (function(c, l, a, r, i, t, y) {
            c[a] = c[a] || function() { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r);
            t.async = 1;
            t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0];
            y.parentNode.insertBefore(t, y);
          })(window, document, "clarity", "script", "ij2ib4cmwb");
        `
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(organizationSchema)
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(WebsiteSchema)
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
          !function(f,b,e,v,n,t,s) {
            if(f.fbq)return; n=f.fbq=function(){n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)};
            if(!f._fbq) f._fbq=n; n.push=n; n.loaded=!0; n.version='2.0';
            n.queue=[]; t=b.createElement(e); t.async=!0;
            t.src=v; s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
          }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
  
          fbq('init', '466082886032975');
          fbq('track', 'PageView');
        `
          }}
        />
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src="https://www.facebook.com/tr?id=466082886032975&ev=PageView&noscript=1"
          />
        </noscript>
      </Head>

      <>
        <>
          {/* Google analytics */}
          <Script
            strategy="lazyOnload"
            src="https://www.googletagmanager.com/gtag/js?id=UA-8671114-2"
          />
          <Script id="google-analytics" strategy="lazyOnload">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-8671114-2', {
          page_path: window.location.pathname,
          });
      `}
          </Script>
          {/* <!-- Google tag (gtag.js) --> */}
          <Script
            async
            id="googles AW account"
            src="https://www.googletagmanager.com/gtag/js?id=AW-10864526481"
            strategy="lazyOnload"
          ></Script>

          {/* <!-- Google Tag Manager --> */}
          <Script
            id="google-tag-manager"
            strategy="lazyOnload"
          >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PDBW462');`}</Script>
          {/* <!-- End Google Tag Manager --> */}
        </>

        {/* <Script
            id="batbing"
            defer
          >{`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"27022747"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}</Script> */}
      </>

      <AppoloProvider>
        <main className={poppins.className}>
          {/* {delayAnalyticsLoad && <Notifications />} */}
          <div className="min-h-screen scroll-m-96" id="website-navbar">
            <PaxProvider>
              <AttractionCheckoutProvider>
                <SearchProvider>
                  <AuthContextProvider>
                    {!routesWithoutNavBar.includes(router.route) && <NavBar />}
                    <Component {...pageProps} />
                    {shouldShowFeedbackButton && <FeedBackButton />}
                  </AuthContextProvider>
                </SearchProvider>
              </AttractionCheckoutProvider>
            </PaxProvider>
          </div>
          {shouldShowFooter && <Footer />}

          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={1}
          />
        </main>
      </AppoloProvider>
    </>
  );
}
